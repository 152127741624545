"use client";

import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./style.css";

const settings = (lang: string) => ({
  dots: false,
  className: "center",
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  centerPadding: "60px",
  nextArrow: lang === "ar" ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />,
  prevArrow: lang === "ar" ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />,
  rtl: true,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
  ],
});

export default function PopularReviewsSlider({ lang }: { lang: string }) {
  const sliderRef = useRef(null);
  useEffect(() => {
    if (sliderRef.current) {
      (sliderRef.current as any).slickGoTo(0);
    }
  }, []);
  return (
    <Slider ref={sliderRef} {...settings(lang)}>
      <Box
        sx={{
          width: "200px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          p: "24px",
          borderRadius: "16px",
          background: "#f4f6f8",
          pl: "24px",
        }}
      >
        <Typography
          sx={{ fontSize: "12px", fontWeight: "400", color: "#919EAB" }}
        >
          24 Oct 2024
        </Typography>
        <Typography
          sx={{ fontSize: "14px", fontWeight: "600", color: "#1C252E" }}
        >
          Jayvion Simon
        </Typography>
        <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
          <Box display={"flex"} alignItems={"center"}>
            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />
            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />
            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />

            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />

            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />
          </Box>
        </Box>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#637381",
            lineHeight: "1.5",
          }}
        >
          Amazing experience i love it a lot. Thanks to the team that dreams
          come true, great! I appreciate there attitude and approach.
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          p: "24px",
          borderRadius: "16px",
          background: "#f4f6f8",
          mr: "24px",
        }}
      >
        <Typography
          sx={{ fontSize: "12px", fontWeight: "400", color: "#919EAB" }}
        >
          23 Oct 2024
        </Typography>
        <Typography
          sx={{ fontSize: "14px", fontWeight: "600", color: "#1C252E" }}
        >
          Lucian Obrien
        </Typography>
        <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
          <Box display={"flex"} alignItems={"center"}>
            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />
            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />
            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />

            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />

            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />
          </Box>
        </Box>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#637381",
            lineHeight: "1.5",
          }}
        >
          Amazing experience i love it a lot. Thanks to the team that dreams
          come true, great! I appreciate there attitude and approach.
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          p: "24px",
          borderRadius: "16px",
          background: "#f4f6f8",
          mr: "24px",
        }}
      >
        <Typography
          sx={{ fontSize: "12px", fontWeight: "400", color: "#919EAB" }}
        >
          22 Oct 2024
        </Typography>
        <Typography
          sx={{ fontSize: "14px", fontWeight: "600", color: "#1C252E" }}
        >
          Deja Brady
        </Typography>
        <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
          <Box display={"flex"} alignItems={"center"}>
            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />
            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />
            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />

            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />

            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />
          </Box>
        </Box>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#637381",
            lineHeight: "1.5",
          }}
        >
          Amazing experience i love it a lot. Thanks to the team that dreams
          come true, great! I appreciate there attitude and approach.
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          p: "24px",
          borderRadius: "16px",
          background: "#f4f6f8",
          mr: "24px",
        }}
      >
        <Typography
          sx={{ fontSize: "12px", fontWeight: "400", color: "#919EAB" }}
        >
          21 Oct 2024
        </Typography>
        <Typography
          sx={{ fontSize: "14px", fontWeight: "600", color: "#1C252E" }}
        >
          Harrison Stein
        </Typography>
        <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
          <Box display={"flex"} alignItems={"center"}>
            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />
            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />
            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />

            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />

            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />
          </Box>
        </Box>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#637381",
            lineHeight: "1.5",
          }}
        >
          Amazing experience i love it a lot. Thanks to the team that dreams
          come true, great! I appreciate there attitude and approach.
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          p: "24px",
          borderRadius: "16px",
          background: "#f4f6f8",
          mr: "24px",
        }}
      >
        <Typography
          sx={{ fontSize: "12px", fontWeight: "400", color: "#919EAB" }}
        >
          23 Oct 2024
        </Typography>
        <Typography
          sx={{ fontSize: "14px", fontWeight: "600", color: "#1C252E" }}
        >
          Ahmed Obrien
        </Typography>
        <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
          <Box display={"flex"} alignItems={"center"}>
            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />
            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />
            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />

            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />

            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />
          </Box>
        </Box>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#637381",
            lineHeight: "1.5",
          }}
        >
          Amazing experience i love it a lot. Thanks to the team that dreams
          come true, great! I appreciate there attitude and approach.
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          p: "24px",
          borderRadius: "16px",
          background: "#f4f6f8",
          mr: "24px",
        }}
      >
        <Typography
          sx={{ fontSize: "12px", fontWeight: "400", color: "#919EAB" }}
        >
          22 Oct 2024
        </Typography>
        <Typography
          sx={{ fontSize: "14px", fontWeight: "600", color: "#1C252E" }}
        >
          Aya Brady
        </Typography>
        <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
          <Box display={"flex"} alignItems={"center"}>
            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />
            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />
            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />

            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />

            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />
          </Box>
        </Box>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#637381",
            lineHeight: "1.5",
          }}
        >
          Amazing experience i love it a lot. Thanks to the team that dreams
          come true, great! I appreciate there attitude and approach.
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          p: "24px",
          borderRadius: "16px",
          background: "#f4f6f8",
          mr: "24px",
        }}
      >
        <Typography
          sx={{ fontSize: "12px", fontWeight: "400", color: "#919EAB" }}
        >
          22 Oct 2024
        </Typography>
        <Typography
          sx={{ fontSize: "14px", fontWeight: "600", color: "#1C252E" }}
        >
          Aya Brady
        </Typography>
        <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
          <Box display={"flex"} alignItems={"center"}>
            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />
            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />
            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />

            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />

            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />
          </Box>
        </Box>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#637381",
            lineHeight: "1.5",
          }}
        >
          Amazing experience i love it a lot. Thanks to the team that dreams
          come true, great! I appreciate there attitude and approach.
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          p: "24px",
          borderRadius: "16px",
          background: "#f4f6f8",
          mr: "24px",
        }}
      >
        <Typography
          sx={{ fontSize: "12px", fontWeight: "400", color: "#919EAB" }}
        >
          22 Oct 2024
        </Typography>
        <Typography
          sx={{ fontSize: "14px", fontWeight: "600", color: "#1C252E" }}
        >
          Aya Brady
        </Typography>
        <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
          <Box display={"flex"} alignItems={"center"}>
            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />
            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />
            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />

            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />

            <StarRateRoundedIcon
              sx={{
                color: "#faaf00",
                fontSize: "16px",
              }}
            />
          </Box>
        </Box>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#637381",
            lineHeight: "1.5",
          }}
        >
          Amazing experience i love it a lot. Thanks to the team that dreams
          come true, great! I appreciate there attitude and approach.
        </Typography>
      </Box>
    </Slider>
  );
}
