"use client";

import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

const Timer = ({
  targetDate,
  dark,
  short,
}: {
  targetDate: string | number | Date;
  dark?: boolean;
  short?: boolean;
}) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <Box display="flex" justifyContent="center" alignItems="center" gap="8px">
      {Object.keys(timeLeft).map((interval) => (
        <Box
          key={interval}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
                width: "56px",
                height: "44px",
                borderRadius: "8px",
                border: "1px solid #919eab3d",
                backgroundColor: dark ? "#1C252E" : "#fff",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  color: dark ? "#fff" : "#1C252E",
                  fontWeight: "600",
                }}
              >
                {timeLeft[interval as keyof typeof timeLeft]}
                {short && (
                  interval === "days" ? "d" :
                  interval === "hours" ? "h" :
                  interval === "minutes" ? "m" :
                  interval === "seconds" ? "s" : ""
                )}
              </Typography>
            </Box>

            {!short && (
              <Typography sx={{ fontSize: "12px", color: "#637381" }}>
                {interval}
              </Typography>
            )}
          </Box>

          {interval !== "seconds" && (
            <Typography
              sx={{ fontSize: "28px", color: "#637381", alignSelf: "baseline" }}
            >
              :
            </Typography>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default Timer;
